<template>
    <v-form ref="uploadExcelForm">
      
      <div class="mb-4">
      Import mode:<br/>
      <v-btn-toggle v-model="mode" mandatory class="multiline-btns">
        <v-btn value="FRESH" small>
          Fresh import<br/>(replaces all current data)
        </v-btn>
        <v-btn value="MERGE" small>
          Updates only<br/>(merge with current data)
        </v-btn>
      </v-btn-toggle>
      </div>

      <v-file-input accept="*/text" label="Select a .xlsx or .csv file" v-model="file" v-on:change="fileSelected"></v-file-input>
      <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success"><strong>{{ syncResult.msg || 'All good! Registration data is saved.' }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info">
        <p><strong>{{ syncResult.msg }}</strong></p>
        <v-btn color="white" outlined @click="forceUpload" :loading="$store.getters.isLoading">Force Upload</v-btn>
      </v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      
      <v-btn color="primary" outlined @click="uploadFile" :loading="$store.getters.isLoading">Upload file</v-btn>
      <span class="ml-4 text-muted">Uploading a new file will <strong>replace</strong> all manually made data changes.</span>
    </v-form>
</template>

<script>

import eventManagerService from "@/services/eventManagerService";
import orgManagerService from "@/services/orgManagerService";

export default {
  name: "CsvUploadForm",
  props: {
    eventId: String,
    groupId: String,
  },
  data: function() {
    return {
      file: null,
      syncResult: null,
      forceImport: false,
      mode: 'FULL',
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async fileSelected() {
      this.syncResult = null;
    },

    async forceUpload() {
      this.forceImport = true;
      await this.uploadFile();
    },

    async uploadFile() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        formData.append("file", this.file, this.file.name);
        formData.append("eventId", this.eventId);
        formData.append("raceId", this.raceId);
        formData.append("force_upload", this.forceImport);
        formData.append("mode", this.mode);
        if (this.groupId) {
          this.syncResult = (await orgManagerService.uploadRegistrations(this.groupId, formData)).data;
        }
        else {
          this.syncResult = (await eventManagerService.uploadRegistrations(this.eventId, formData)).data;
        }
        if (this.syncResult.status == 'OK'){
          this.forceImport = false;
          this.$refs.uploadExcelForm.reset();
          this.$emit('uploaded'); 
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>